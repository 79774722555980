import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Contact from '../views/Contact'

import Scene from '@/views/Scene.vue'

import Enterprise from '../views/enterprise'
import Entrance from '../views/Entrance'






Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '国适优行',
        },
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们',
        },
    },
    {
        path: '/Scene',
        name: 'Scene',
        component: Scene,
        meta: {
            title: '场景方案',
        },
    },
    {
        path: '/enterprise',
        name: 'enterprise',
        component: Enterprise,
        meta: {
            title: '企业用车',
        },
    },
    {
        path: '/Entrance',
        name: 'Entrance',
        component: Entrance,
        meta: {
            title: '全球外宾出入境服务',
        },
    },
    {
        path: '/Contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: '联系我们',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
