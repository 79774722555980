<template>
    <Layout>
        <div class="col-lg-12 conwrap">
            <img style="width: 100%;margin-top: -2px;" src="../assets/images/contact/bg.png" alt="">
            <div class="col-lg-12 bg">
                <div class="conter">
                    <h1>联系我们</h1>
                    <h2>国适优行 为企业而行</h2>
                    <h3>地址：北京市朝阳区企发大厦F座919</h3>
                    <h4>客服电话：18910051812</h4>
                    <div v-for="(item,index) in date" :key="index" class="contant">
                        <img :src="item.icon" alt="商务合作">
                        <span class="span1">{{item.tie}}</span>
                        <span class="span2">{{item.iphone}}</span>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'

import icon1 from '../assets/images/contact/shangwu.png'
import icon2 from '../assets/images/contact/wufu.png'
import icon3 from '../assets/images/contact/zhaoshang.png'





export default {
    name: 'Contact',
    props: {

    },
    components: {
        Layout
    },
    data() {
        return {
            date:[
                {
                    icon:icon1,
                    tie:'商务合作',
                    iphone:'13910712879'
                },
                {
                    icon:icon2,
                    tie:'服务投诉',
                    iphone:'18910051812'
                },
                {
                    icon:icon3,
                    tie:'广告招商',
                    iphone:'13910712879'
                },
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.conwrap{
   position: relative;
    .contant{
        width: 500px;
        height: 100px; /* 设置元素高度为 150px */
        border-bottom: 2px solid #9C9A9A;
        position: relative;
        img{
            position: absolute;
            bottom: 10px;
            left: 0px;
        }
        .span1{
            position: absolute;
            bottom: 5px;
            left: 55px;
            color: #FFFFFF;
            font-size: 20px;
        }
        .span2{
            position: absolute;
            bottom: 5px;
            left: 150px;
            color: #fff;
            font-size: 18px;
        }
    }

    .bg{
        width: 100%;
        height: 100%;
        // background-color: rgba(5,16,97,0.4);
        position: absolute;
        left: 0;
        top: 0;
        
    }
    .conter{
        width: 1200px;
        margin: 0 auto;
        padding-top: 110px;
        h1{
            color: #FFFFFF;
            font-size: 24px;
            font-weight: bold;
        }
        h2{
            color: #FFFFFF;
            font-size: 20px;
            margin-top: 35px;
        }
        h3{
            color: #FFFFFF;
            font-size: 18px;
            margin-top: 55px;
        }
        h4{
            color: #FFFFFF;
            font-size: 18px;
        }
    }
}
</style>