<template>
    <main class="page-wrapper">
        <Headertwo />

        <div class="main-content">
            <slot></slot>
        </div>

        <Footer v-if="footerStyle === 1" />
        <Footertwo v-if="footerStyle === 2" />
        <Footerthree v-if="footerStyle === 3" />

    </main>
</template>

<script>
import Header from '../../views/header/index.vue'
import Headertwo from '../../views/header/indextwo.vue'

import Footer from '../../views/footer/index.vue'
import Footertwo from '../../views/footer/indextwo.vue'
import Footerthree from '../../views/footer/indexthree.vue'



export default {
    name: 'Layout',
    components: { Header, Footer, Headertwo, Footertwo, Footerthree },
    props: {
        footerStyle: {
            type: Number,
            default: 1
        },
    }
}
</script>
<style scoped>
.main-content{
    /* width: 1200px;
    margin: 0 auto; */
}
</style>