<template>
    <Layout>
        <div class="bodyscence">
            <div class="scene col-lg-12">
                <div class="scene-item col-lg-6">
                    <p class="titleone">企业用车</p>
                    <p class="titletwo">提供企业所需的全场景用车需求可通过后台实现员工
                        权限划分，费用管控等级划分，合规管理，降本增效</p>
                    <router-link class="but" to="/enterprise">立即加入</router-link>
                </div>
                <div class="scene-item col-lg-6">
                    <img src="../assets/images/bg/qiye.png" alt="">
                </div>
                <div class="scene-item col-lg-6">
                    <img src="../assets/images/bg/churu.png" alt="">
                </div>
                <div class="scene-item col-lg-6">
                    <p class="titleone">全球外宾出入境服务</p>
                    <p class="titletwo">提供多语种的下单后台，入境全流程专人引导满足客户
                        入境后各场景的用车需求，操作简单，专属衔接流程高
                        端服务质量</p>
                        <router-link class="but" to="/Entrance">
                            立即加入
                        </router-link>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'



export default {
    name: 'Scene',
    props: {

    },
    components: {
        Layout,
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.bodyscence {
    padding: 88px;
    background: #F6F8FF;
}

.scene {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.scene-item {
    position: relative;
    background: #fff;

    .titleone {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        padding-top: 51px;
        padding-left: 55px;
    }

    .titletwo {
        color: #333333;
        font-size: 20px;
        padding-left: 55px;
        padding-right: 55px;
    }
    .but {
        width: 112px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        background: #FF8D1A;
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        position: absolute;
        right: 55px;
        bottom: 55px;


    }

    img {
        width: 100%;
    }
}
</style>