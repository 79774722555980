<template>
    <Layout :footer-style="3">
        <div class="col-lg-12 entone">
            <div style="width: 1200px;margin: 0 auto;padding-bottom: 85px;">
                <div class="title">欢迎加入全球外宾出入境服务</div>
                <img style="width: 100%;margin-top: 50px;" src="../assets/images/scece/activityone.png" alt="">
            </div>
        </div>

        <div class="titletwo">多元<div>化</div>场景</div>
        <div class="col-lg-12 swiper">
            <div class="swiper111">
                <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" class="prev">
                    <img :src="leftimg" @click="arrowClick('prev')" />
                </div>
                <el-carousel arrow="never" :autoplay="false" height="350px" type="card" ref="cardShow">
                    <el-carousel-item v-for="(item, index) in swperdata" :key="index">
                        <img class="fuwuicon" :src="item.img" alt="">
                        <div class="fuwutext1">{{ item.text1 }}</div>
                        <div class="fuwutext2">{{ item.text2 }}</div>
                    </el-carousel-item>
                </el-carousel>
                <div class="next">
                    <img @mouseenter="onMouseEnterright" @mouseleave="onMouseLeaveright" :src="rightimg"
                        @click="arrowClick('next')" />
                </div>
            </div>
        </div>
        <div class="col-lg-12 flowpath">
            <div class="scecepersin">
                <div class="scecexian"></div>
                <div class="jieji ">
                    <img class="imgbig" src="../assets/images/scece/jieji.png" alt="">
                    <div class="jiejitext">接机用车</div>
                    <p class="jiejitext1">根据航班固定起点，操作更便捷</p>
                </div>
                <img class="imgasmoll" src="../assets/images/scece/xright.png" alt="">
                <div class="jieji ">
                    <img class="imgbig" src="../assets/images/scece/yongcar.png" alt="">
                    <div class="jiejitext">境内用车</div>
                    <p class="jiejitext1">提供预约用车以及包车服务，满足多种用车需求</p>
                </div>
                <img class="imgasmoll" src="../assets/images/scece/xright.png" alt="">
                <div class="jieji ">
                    <img class="imgbig" src="../assets/images/scece/songji.png" alt="">
                    <div class="jiejitext">送机用车</div>
                    <p class="jiejitext1">灵活选择送机需求，出行更自由</p>
                </div>
                <img class="imgasmoll" src="../assets/images/scece/xright.png" alt="">

                <div class="jieji ">
                    <img class="imgbig" src="../assets/images/scece/zengzhi.png" alt="">
                    <div class="jiejitext">增值服务</div>
                    <p class="jiejitext1">多样化增值服务，出行不仅局限于出行</p>
                </div>
                <div class="jieji ">
                    <img class="imgbigtwo" src="../assets/images/scece/carone.png" alt="">
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'

import icon1 from '@/assets/images/scece/swiper1.png';
import icon2 from '@/assets/images/scece/swiper2.png';
import icon3 from '@/assets/images/scece/swiper3.png';

import left from '@/assets/images/scece/left.png';
import isleft from '@/assets/images/scece/isleft.png';
import right from '@/assets/images/scece/right.png';
import isright from '@/assets/images/scece/isright.png';





export default {
    name: 'Entrance',
    props: {

    },
    components: {
        Layout
    },
    data() {
        return {
            leftimg: left,
            rightimg: right,
            swperdata: [
                {
                    img: icon1,
                    text1: '服务人员专业化',
                    text2: '严格的岗前培训以及筛选标准为用户提供更专业的服务'
                },
                {
                    img: icon2,
                    text1: '服务流程一体化',
                    text2: '专业的接机引导人员引领客人到达指定上车位置'
                },
                {
                    img: icon3,
                    text1: '服务场景多样化',
                    text2: '更多细分场景出行不仅局限于出行'
                },
            ]
        }
    },
    methods: {
        arrowClick(val) {
            if (val === 'next') {
                this.$refs.cardShow.next()
            } else {
                this.$refs.cardShow.prev()
            }
        },
        onMouseEnter() {
            this.leftimg = isleft
        },
        onMouseLeave() {
            this.leftimg = left
        },
        onMouseEnterright() {
            this.rightimg = isright
        },
        onMouseLeaveright() {
            this.rightimg = right
        },
    }
}
</script>

<style>
.el-carousel__indicators--outside {
    margin-top: 30px!important;
    margin-left: 45px!important;
}
</style>
<style lang="scss" scoped>

.entone {
    background: #F6F8FF;
}

.flowpath {
    background-image: url(../assets/images/bg/scecebg.png);
    height: 415px;
    background-size: 100% 100%;

    .scecepersin {
        height: 300px;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .jieji {
            // z-index: 2;
            text-align: center;
            position: relative;

            .jiejitext {
                color: #CDB87F;
                font-size: 24px;
                font-weight: bold;
                position: absolute;
                bottom: -55px;
                left: -33px;
                width: 180px;
            }

            .jiejitext1 {
                color: #CDB87F;
                font-size: 20px;
                position: absolute;
                top: 170px;
                left: -33px;
                width: 180px;
            }

            .imgbigtwo {
                z-index: 2;
                width: 150px;
                height: 53px;
            }
        }


        .imgasmoll {}



        .scecexian {
            width: 100%;
            height: 3px;
            background: linear-gradient(to left, transparent 0%, transparent 50%, #CEB77F 50%, #CEB77F 100%);
            background-size: 10px 3px;
            background-repeat: repeat-x;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 0;
        }
    }

}




.fuwutext1 {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    margin-top: 180px;
    text-align: center;
}

.fuwutext2 {
    color: #4D4D4D;
    font-size: 20px;
    width: 80%;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
    margin: 10px auto;
}

.fuwuicon {
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
}

.prev {
    position: absolute;
    left: -180px;
    top: 50%;
    cursor: pointer;
    transform: translate(0, -50%);

    img {
        width: 72px;
        height: 72px;
    }
}

.el-carousel--horizontal {
    padding-top: 30px;
}


.next {
    cursor: pointer;
    position: absolute;
    right: -180px;
    top: 50%;
    transform: translate(0, -50%);

    img {
        width: 72px;
        height: 72px;
    }
}

.is-active {
    box-shadow: 0px 0px 36px #E69F3C;
}

.swiper111 {
    width: 1000px;
    position: relative;
}

.swiper {
    display: grid;
    place-items: center;
    height: 600px;
}

.el-carousel__item {
    margin-left: 100px;
    border-radius: 50%;
    width: 350px;
    background-image: url(../assets/images/scece/swiper.png);
    background-size: 100% 100%;
}

.title {
    color: #333333;
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    padding-top: 88px;
}


.titletwo {
    color: #333333;
    font-size: 36px;
    text-align: center;
    margin-top: 90px;
    font-weight: bold;

    div {
        display: inline-block;
        border-bottom: 5px solid #FF8D1A;
    }
}
</style>