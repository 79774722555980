<template>
    <div class="footer">
        <div class="footerone" style="position: relative;">
            <div class="logo">
                <span class="logitile">加入我们</span>
            </div>
            <div class="QRpro">
                <img src="../../assets/images/fan.png" alt="">
                <p class="describe">扫一扫加入我们</p>
            </div>

            <div class="email"><span>服务邮箱：<span style="padding-left: 5px;" class="span_bot">zhangjianxin@ycxbj.cn</span></span></div>
            <div class="iphone"><span style="letter-spacing: 6px;">张经理：<span class="span_bot">18910051812</span></span></div>
            <!-- <p class="beian">Copyright@2017-2023 悦出行（北京）网络科技有限公司 All Rights Reserved | 北京 ICP 2023002343</p> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.span_bot {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 0px;
}

.QRpro {
    text-align: center;
    position: absolute;
    left: 0px;
    top: 150px;

    img {
        width: 120px;
        height: 120px;
    }
}

.describe {
    color: #fff;
    font-size: 18px;
    margin-top: 20px;
}

.footerone {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 水平居中 */
    justify-content: flex-start;
    /* 从顶部开始排列 */
}

.footer {
    width: 100%;
    height: 388px;
    background: #383838;
    position: relative;

    .beian {
        color: #F7F7F7;
        font-size: 24px;
        margin-top: auto;
        /* 使用 auto 将 beian 推到底部 */
        text-align: center;
        /* 文本水平居中 */
    }
}

.logoimg {
    width: 67px;
    height: 67px;
}

.logo {
    position: absolute;
    left: 0px;
    top: 31px;
}

.email {
    position: absolute;
    left: 20%;
    top: 170px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.iphone {
    position: absolute;
    left: 20%;
    bottom: 140px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.logitile {
    font-size: 24px;
    color: #fff;
    vertical-align: middle;
}</style>