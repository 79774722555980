// 创建文件en/index.js英文
const en = {
    navone: 'Home',
    navtwo: 'About',
    navthree: 'Scenario',
    navfor: 'Contact',
    navfive: 'Free Logo',
    logo:'GuoShiYouXing'

  }
  export default en