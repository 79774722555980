<template>
    <Layout>
        <!-- 第一屏 -->
        <div class="col-lg-12" id="bodytwo">
           <div class="aboutone">
            <div class="hometwo_left">
                <div class="redabout">
                    <div class="bottred">关于</div>
                    <span class="span1">国适优行</span>
                </div>
                <div class="miaoshu">
                    国适优行-悦出行（北京）网络科技有限公司，是国内卓越的移动出行科技平台，提供企业所需的网约车、接送机、中高端定制等多元化出行的聚合打车服务，高效融合互联网数据及交通大数据平台，综合评估交通拥堵分
                    析，提高车辆使用效率，智能出行更愉悦。

                </div>
            </div>
            <img class="home_bg" src="../assets/images/bg/home_bgtwo.png" alt="car">
           </div>
        </div>
        <!-- 第二屏 -->
        <div class="col-lg-12" id="course">
            <div class="courseleft">
                <div class="courseleftdate">
                    <div class="redsu"></div>
                    <div class="cover">
                        <img class="img1" @click="upper" src="../assets/images/shang.png" alt="">
                        <img class="img2" @click="below" src="../assets/images/xia.png" alt="">
                        <div class="hengline"></div>
                    </div>
                    <div class="yearpon" ref="menu">
                        <div class="yearpontwo" :style="{ top: topdistance + 'px' }">
                            <div v-for="(item, index) in years" :key="index" :style="{ color: index === isnum ? '#FB7100' : '#333333' }"  >{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="courseright">
                <div class="title">
                    <span>企</span>
                    <div class="titlered">业历</div>
                    <span>程</span>
                </div>

                <div class="describe">{{characters[isnum]}}</div>
            </div>
        </div>
        <!-- 第三屏 -->
        <div class="col-lg-12" id="natural">
            <div class="title">资<div class="red_but">源整</div>合</div>
            <div style="text-align: center;">
            <img class="imgziyuan" src="../assets/images/bg/ziyuanbg.png" alt="">
            </div>
        </div>
    
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'



export default {
    name: 'About',
    props: {

    },
    components: {
        Layout
    },
    data() {
        return {
            years: ['2020.01.06', '2022.04.12', '2022.06.18', '2022.08.08', '2022.09.15',],
            topdistance: 0,
            isnum: 0,
            characters:[
                '2020年技术团队雏形建立为国内多家出行平台提供技术支持和外包服务',
                '建立核心团队，制定以企业服务为核心，深耕多元化场景的商业理念团队内包含从事多年出行行业专家及精通企业财税资金管理等方面的精英',
                '第一笔天使金到位项目正式启动',
                '平台与首汽、神州、曹操、T3等十余家网约车平台签署战略合作同盟协议',
                '平台完成内部测试，推行小范围试运行，以主流网约车平台架构为依托搭载独有七大技术模块，只为更好服务与企业'
            ]
        }
    },
    methods: {
        // 下箭头
        below() {
            const index = this.years.length - 1
            if (this.isnum == index) {
                return
            } else {
                this.isnum++
                this.topdistance -= 95
            }
        },
        //上肩头
        upper() {
            if (this.isnum == 0) {
                return
            } else {
                this.isnum--
                this.topdistance += 95
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .describe{
        color: #4D4D4D;
        font-size: 16px;
        letter-spacing: 5px;
        position: absolute;
        margin-left: 60px;
        margin-top: 40px;

    }
.yearpon {
    width: 180px;
    height: 285px;
    overflow: hidden;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    position: absolute;
    top: -2px;
    right: 100px;
}

.yearpontwo {
    transition: top 0.5s ease;
    position: absolute;

    div {
        width: 135px;
        height: 95px;
        line-height: 95px;
    }
}

.isactive {
    color: #FF8D1A;
}

.displaxone {
    width: 40%;
}

.displaxtwo {
    height: 274px;
}

.displaxthree {
    width: 40%;
}

.displax {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#natural {
    width: 100%;
    padding: 100px;
    background: #F6F8FF;

    .red_but {
        border-bottom: 5px solid #FF8D1A;
        line-height: 55px;
        display: inline-block;
    }
    .imgziyuan{
        margin-top: 35px;
    }

    .title {
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #333333;
    }

}

.courseright {
    width: 50%;
    height: 100%;
    position: relative;

    .title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        margin: 100px 30px;
    }

    .titlered {
        display: inline-block;
        border-bottom: 5px solid #FE7101;
    }
}



#course {
    height: 689px;
    width: 1200px;
    margin: 0 auto;
    background: #FDFDFD;
    display: flex;
    justify-content: space-between;

    .courseleft {
        width: 50%;
        height: 100%;
        border-right: 3px solid #E3E3E3;
        display: flex;
        align-items: center;

        .courseleftdate {
            width: 100%;
            height: 95px;
            border-top: 2px solid #E3E3E3;
            border-bottom: 2px solid #E3E3E3;
            position: relative;

            span {
                font-size: 32px;
                color: #262626;
                position: absolute;
                top: 50%;
                right: 100px;
                transform: translate(0, -50%);
            }

            .redsu {
                width: 5px;
                height: 95px;
                background: #FF8D1A;
                position: absolute;
                right: -4.5px;
                top: -2px;
            }

            .cover {
                width: 80px;
                height: 99px;
                background: #FDFDFD;
                position: absolute;
                top: -2px;
                right: 400px;
                z-index: 1;

                .img1 {
                    position: absolute;
                    left: 50%;
                    top: -4px;
                    transform: translate(-50%, 0);
                    cursor: pointer;
                }

                .img2 {
                    cursor: pointer;
                    position: absolute;
                    left: 50%;
                    bottom: -4px;
                    transform: translate(-50%, 0);
                }

                .hengline {
                    width: 65px;
                    height: 2px;
                    background: #8A8A8A;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

}


#bodytwo {
    width: 100%;
    padding: 87px;
    background: #F6F8FF;
    .aboutone{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hometwo_left {
        .redabout {
            display: inline-block;
            color: #333333;
            font-size: 24px;
            font-weight: bold;

            .span2 {
                font-size: 24px;
                color: #000000;
                font-weight: 400;
                margin-left: 10px;

            }


        }

        .bottred {
            display: inline-block;
            border-bottom: 5px solid #FE7101;
            line-height: 50px;
        }

        .miaoshu {
            color: #333333;
            font-size: 20px;
            width: 340px;
            margin-top: 45px;
           
        }
    }
}
</style>