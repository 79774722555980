<template>
    <div class="footer">
        <div class="footerone" style="position: relative;">
            <div class="logo">
                <img class="logoimg" src="../../assets/images/logo/logo.png" alt="logo">
                <span class="logitile">{{ $t("logo") }}</span>
            </div>
            <div class="email"><span>服务邮箱：ycxbj@ycxbj.cn</span></div>
            <p class="beian">Copyright@2017-2024 悦出行（北京）网络科技有限公司 All Rights Reserved | <a href="https://beian.miit.gov.cn/#/Integrated/index
">京ICP备2023002343号-2</a></p>

            <div class="QRpro">
                <img src="../../assets//images/qrcode.png" alt="">
                <p class="describe">扫一扫了解更多优惠政策</p>
            </div>
        </div>




    </div>
</template>

<script>
export default {
    props: {

    },
    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.QRpro {
    text-align: center;
    position: absolute;
    top: 88px;
    right: 0px;
    img{
        width: 115px;
        height: 115px;
    }
}

.describe {
    color: #F7F7F7;
    font-size: 18px;
    margin-top: 20px;
}

.footerone {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 水平居中 */
    justify-content: flex-start;
    /* 从顶部开始排列 */
}

.footer {
    width: 100%;
    height: 388px;
    background: #383838;
    position: relative;

    .beian {
        color: #FFFFFF;
        font-size: 18px;
        margin-top: auto;
        /* 使用 auto 将 beian 推到底部 */
        text-align: center;

        /* 文本水平居中 */
        a {
            text-decoration: none;
            /* 去除下划线 */
            color: inherit;
            /* 继承父元素的颜色 */
            cursor: pointer;
            /* 将鼠标光标设置为手型，指示可以点击 */
        }
    }
}

.logoimg {
    width: 53px;
    height: 53px;
}

.logo {
    position: absolute;
    left: 0px;
    top: 88px;
}

.email {
    position: absolute;
    left: 0px;
    top: 178px;
    color: #F7F7F7;
    font-size: 18px;
}

.logitile {
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
    vertical-align: middle;
}
</style>