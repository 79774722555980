<template>
    <Layout :footer-style="2">
        <div class="col-lg-12 entone">
            <div style="width: 1200px;margin: 0 auto;padding-bottom: 85px;">
                <div class="title">欢迎加入企业用车</div>
                <img style="width: 100%;margin-top: 65px;" src="../assets/images/scece/activityone.png" alt="">
            </div>
        </div>


        <div class="titletwo">品<div>质服</div>务</div>
        <div class="homethree col-lg-12">
            <div v-for="(item, index) in homethdata" :key="index" class="homethreepro col-lg-3">
                <img class="iconimg" :src="item.icin" alt="">
                <img class="col-lg-12" :src="item.img" alt="">
                <div class="bottom_miaoshu">
                    <p class="bottom_miaoshu1">{{ item.title }}</p>
                    <div class="bottom_miaoshu2">{{ item.title1 }}</div>
                </div>
            </div>
        </div>


        <!-- 服务场景 -->
        <div class="enterpr_t">
            <div class="titleth_w">服<div>务场</div>景</div>
            <div class="fuwuchanjing" style="display: flex;">
                <div class="col-lg-4 content-wrapper">
                    <img src="../assets/images/scece/baoche.png" alt="">
                    <div class="text-content">
                        <div class="text-1">包车</div>
                        <div class="text-2">多钟车型，多种套餐可供选择，更有定制化包车。</div>
                    </div>
                </div>
                <div class="col-lg-4 content-wrapper">
                    <img src="../assets/images/scece/jiesongji.png" alt="">
                    <div class="text-content">
                        <div class="text-1">接送机</div>
                        <div class="text-2">根据航班接机，节约出行成本。</div>
                    </div>
                </div>
                <div class="col-lg-4 content-wrapper">
                    <img src="../assets/images/scece/jiesongzhan.png" alt="">
                    <div class="text-content">
                        <div class="text-1">接送站</div>
                        <div class="text-2">覆盖全国各大车站，出行更便捷。</div>
                    </div>
                </div>
            </div>
            <div style="display: flex;justify-content: center;" class="fuwuchanjing_two">
                <div class="col-lg-4 content-wrapper">
                    <img src="../assets/images/scece/churujing.png" alt="">
                    <div style="text-align: initial;" class="text-content">
                        <div class="text-1">出入境服务</div>
                        <div class="text-2">外宾入境，享受星级便捷服务。</div>
                    </div>
                </div>
                <div class="col-lg-4 content-wrapper">
                    <img src="../assets/images/scece/huiyi.png" alt="">
                    <div style="text-align: initial;" class="text-content">
                        <div class="text-1">会议用车</div>
                        <div class="text-2">一站式用车服务，满足企业需求。</div>
                    </div>
                </div>

            </div>
        </div>



        <div class="forwrap">
            <div class="lefrtext">
                <div class="titlethree">企<div>业管</div>理</div>
                <div class="textone">
                    层级用车管理、精准化用车等级、多维度费用分析降本增效，给企业用户在此业务板块降低30%的成本支出。
                </div>
            </div>
            <div class="rightimng">
                <img src="../assets/images/scece/mac.png" alt="">
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'

import bg1 from '@/assets/images/scece/fore.png';
import bg2 from '@/assets/images/scece/one.png';
import bg3 from '@/assets/images/scece/two.png';
import bg4 from '@/assets/images/scece/three.png';
import icon1 from '@/assets/images/scece/lvyue.png';
import icon2 from '@/assets/images/scece/hegui.png';
import icon3 from '@/assets/images/scece/jbzx.png';
import icon4 from '@/assets/images/scece/kefu.png';





export default {
    name: 'enterprise',
    props: {

    },
    components: {
        Layout
    },
    data() {
        return {
            homethdata: [
                {
                    img: bg1,
                    icin: icon1,
                    title: '百分百履约',
                    title1: '90%线上车企满足绝大多数用车场景需求，更有线下车企满足其余用车场景需求，履约率100%'
                },
                {
                    img: bg2,
                    icin: icon2,
                    title: '用车合规',
                    title1: '后台定制地址校对、时间限定、人员分级、额度分配…从更多角度规范企业用车'
                },
                {
                    img: bg3,
                    icin: icon3,
                    title: '降本增效',
                    title1: '有效降低企业20%的人工成本60%的时间成本以及40%的管理成本和20%的财务成本'
                },
                {
                    img: bg4,
                    icin: icon4,
                    title: '无忧服务',
                    title1: '售后、客诉服务团队7*24小时中英文双语客服团队'
                },
            ],
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.fuwuchanjing_two{
    width: 1200px;
    margin: 0 auto;
}
.enterpr_t{
    background: #F6F8FF;
    padding-top: 1px;
    padding-bottom: 40px;
}
.fuwuchanjing {
    width: 1200px;
    margin: 0 auto;
}

.entone {
    background: #F6F8FF;
}

.textone {
    color: #000020;
    font-size: 20px;
    margin-top: 25px;
}

.forwrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    div {
        display: inline-block;
    }
    .lefrtext{
        width:510px;
    }
    .rightimng{
        
    }
}

.content-wrapper {
    display: flex;
    margin-top: 45px;

    .text-1 {
        color: #333333;
        font-size: 24px;
    }

    .text-2 {
        color: #4D4D4D;
        font-size: 20px;
    }

    .text-content {
        margin-top: 10px;
        margin-left: -20px;
        display: inline-block;
    }

   
}

.iconimg {
    max-width: 122px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, 0);

}

.bottom_miaoshu {
    width: 100%;
    height: 180px;
    padding-top: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;

    .bottom_miaoshu1 {
        color: #fff;
        font-size: 24px;
    }

    .bottom_miaoshu2 {
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.homethree {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 88px;
    padding-top: 65px;

}

.homethreepro {
    height: 100%;
    display: inline-block;
    position: relative;
}

.title {
    color: #333333;
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    padding-top: 88px;
}

.titlethree {
    display: block !important;
    color: #333333;
    font-size: 36px;
    font-weight: bold;

    div {
        display: inline-block;
        height: 65px;
        border-bottom: 5px solid #FF8D1A;
    }
}

.titleth_w{
    color: #333333;
    font-size: 36px;
    text-align: center;
    margin-top: 88px;
    font-weight: bold;

    div {
        display: inline-block;
        border-bottom: 5px solid #FF8D1A;
        height: 65px;
    }
}
.titletwo {
    color: #333333;
    font-size: 36px;
    text-align: center;
    margin-top: 88px;
    font-weight: bold;

    div {
        display: inline-block;
        border-bottom: 5px solid #FF8D1A;
        height: 65px;
    }
}
</style>