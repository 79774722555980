<template>
    <div class="header">
        <div class="logo">
            <img class="logoimg" src="../../assets/images/logo/logo.png" alt="logo"><span class="logitile">{{$t("logo")}}</span>
            <el-menu router :default-active="activemeun()" class="el-menu-demo nav" mode="horizontal" @select="handleSelect"
                text-color="#fff" active-text-color="#FC6F00">
                <el-menu-item index="/">{{$t("navone")}}</el-menu-item>
                <el-menu-item index="/About">{{$t("navtwo")}}</el-menu-item>
                <el-menu-item index="/Scene">{{$t("navthree")}}</el-menu-item>
                <el-menu-item index="/Contact">{{$t("navfor")}}</el-menu-item>
                <el-menu-item @click="linktoregister" >{{$t("navfive")}}</el-menu-item>
            </el-menu>
            <div class="langpro"  @click="debouncedToggleLanguage">
                <img v-if="islang" class="lang" src="../../assets/images/logo/zh.png" alt="">
                <img v-else class="lang" src="../../assets/images/logo/en.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    components: {

    },
    data() {
        return {
            islang:true
        }
    },
    created() {
        this.debouncedToggleLanguage = this.debounce(this.toggleLanguage, 200);
    },
    mounted() {
       
    },
    methods: {
        linktoregister() {
            window.location.href="http://admin.gsyxtravel.com/#/register"
        },
        activemeun() {
            if(this.$route.path=='/enterprise' || this.$route.path=='/Entrance') {
                return '/Scene'
            }else{
                return this.$route.path
            }
        },
        handleSelect(key, keyPath) {
            this.$router.push(key)
        },
        debounce(func, delay) {
            let timer;
            return function (...args) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(this, args);
                }, delay);
            };
        },
        toggleLanguage() {
            const lang = localStorage.getItem('lang');
            if (lang === 'zh') {
                this.$i18n.locale = 'en'//切换语言
                localStorage.setItem('lang', 'en');
                this.islang = false
            } else {
                this.$i18n.locale = 'zh'
                localStorage.setItem('lang', 'zh');
                this.islang = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.el-menu.el-menu--horizontal {
    border-bottom: none
}
.lang {
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.logitile{
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
}

.el-menu-item.is-active {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #fff;

    span {
        color: #fff !important;
    }
}
.el-menu-item {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 4px solid #FC6F00;
}

.el-menu--horizontal>.el-menu-item {
    font-size: 20px;
    padding: 0 0px;
}
.logo {
    width: 1200px;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .logoimg{
        width: 53px;
        height: 53px;
    }

    
}
.langpro{
    position: absolute;
    right: 0px;
}

.nav {
    background: none;
    margin-left: 5%;
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.header {
    width: 100%;
    height: 88px;
    position: relative;
    background: rgba(0, 5, 51, 0.5);
}
</style>