// 创建文件zh/index.js中文
const zh = {
  navone: '首页',
  navtwo: '关于我们',
  navthree: '场景方案',
  navfor: '联系我们',
  navfive: '企业注册',
  logo:'国适优行'



}
export default zh