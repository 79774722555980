<template>
    <div>
        <!-- 第一屏 -->
        <div id="body">
            <Header />
            <img class="imgbghaha" src="../assets/images/bg/hone_bg.png" />
        </div>
        <!-- 第二屏 -->
        <div id="bodytwo">
            <div class="home_two">
                <div class="hometwo_left">
                    <div class="redabout">
                        <div class="bottred">关于</div><span>国适优行</span>
                    </div>
                    <div class="miaoshu">
                        国适优行是国内卓越的移动出行科技平台，提供企业所需的网约车、接送机、中高端定制等多元化出行的聚合打车服务，高效融合互联网数据及交通大数据平台，综合评估交通拥堵分
                        析，提高车辆使用效率，智能出行更愉悦。
                    </div>
                </div>
                <img class="home_bg" src="../assets/images/bg/home_bgtwo.png" alt="car">
            </div>
        </div>
        <!-- 第三屏 -->
        <div class="bodythree">
            <div class="home_three">
                <div v-for="(item, index) in homethdata" :key="index" class="homethreepro col-lg-3">
                <img class="col-lg-12" :src="item.img" alt="">
                <div class="bottom_miaoshu">
                    <p class="bottom_miaoshu1">{{ item.title }}</p>
                    <p class="bottom_miaoshu2">{{ item.title1 }}</p>
                </div>
            </div>
            </div>
            
        </div>







        <img class="bottom_img" src="../assets/images/bottom.png" alt="bottom">
        <Footer />

    </div>
</template>

<script>
import Header from '../views/header/index'
import Footer from '../views/footer/index'
import bg1 from '@/assets/images/hometh1.png';
import bg2 from '@/assets/images/hometh2.png';
import bg3 from '@/assets/images/hometh3.png';
import bg4 from '@/assets/images/hometh4.png';


export default {
    name: 'home',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            homethdata: [
                {
                    img: bg1,
                    title: '五大场景',
                    title1: '只为服务标准化、流程简单化'
                },
                {
                    img: bg2,
                    title: '需求定制',
                    title1: '您的需求我们只想做到+1'
                },
                {
                    img: bg3,
                    title: '品质出行',
                    title1: '专业司机 安全保障、7*24h客服'
                },
                {
                    img: bg4,
                    title: '降本增效',
                    title1: '更优惠的价格，更便捷的操作'
                },
            ]

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.home_three{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bodythree{
    width: 100%;
    background: #F6F8FF;
    padding: 70px 0 100px 0;
}
.home_two{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h2 {
    font-size: 120px;
    color: #fff;
}

h5 {
    font-size: 60px;
    color: #fff;
}

h6 {
    font-size: 34px;
    color: #fff;
}

.imgbghaha {
    width: 100%;
    margin-top: -88px;
}


.redline {
    display: inline-block;
    width: 473px;
    height: 8px;
    background: #FE7101;
}



#bodytwo {
    width: 100%;
    padding: 97px;
}



.homethree {}

.homethreepro {
    width: 290px;
    margin-bottom: -6px;
    display: inline-block;
    position: relative;
}

.bottom_miaoshu {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;

    .bottom_miaoshu1 {
        color: #F2F2F2;
        font-size: 24px;
    }

    .bottom_miaoshu2 {
        color: #F2F2F2;
        font-size: 16px;
    }
}

.homethreepro img {
    height: 100%;
    width: 100%;
    display: block;
}

.home_bg {
    min-width: 697px
}

.hometwo_left {

}

.redabout {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
}

.bottred {
    display: inline-block;
    border-bottom: 5px solid #FE7101;
    line-height: 50px;
}

span {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
}

.miaoshu {
    color: #333333;
    font-size: 20px;
    width: 340px;
    margin-top: 45px;
}

.yuan {
    padding-top: 170px;
    margin: 80px auto;
    width: 840px;
    height: 840px;
    background-image: url("../assets/images/bg/yuan.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 120px;
}

.bottom_img {
    width: 54px;
    height: 40px;
    position: fixed;
    left: 50%;
    bottom: 10px;
    margin-left: -27px;
    /* 初始位置，根据实际情况调整 */
    transform: translateY(0);
    /* 设置动画属性 */
    animation: slideDown 1.5s infinite alternate;
    /* 动画名、动画时长、动画次数和方向 */
}

/* 定义动画 */
@keyframes slideDown {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(10px);
        /* 向下移动的距离 */
    }
}</style>